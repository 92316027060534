<template>
    <nav class=" fixed flex-col top-0 z-50 w-full bg-white border-b border-gray-200  lg:px-5 lg:pl-3 flex  ">
        <div class=" flex flex-row ">
            <SidebarToggle />
            <NuxtLink class=" flex flex-row gap-2 px-3 py-2" to="/">
                <img src="~/assets/images/logo.svg" alt="Oman Real Logo" class="w-7 h-7" />
                <h1 class="text-2xl text-black">Admin</h1>
            </NuxtLink>
        </div>
        <ToggleColorMode />

    </nav>



    <!-- </div> -->
</template>

<script setup lang="ts">
</script>