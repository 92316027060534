<template>
    <div v-if="isAllowed">
        <slot />
    </div>
</template>


<script setup lang="ts">
import { useUserRoles } from '~/composables/states';
import { Role } from '~/types/roles';

const props = defineProps<{
    allowedRoles: Role[]
}>()

const userRoles = useUserRoles();

const isAllowed = computed(() => {
    for (const r of userRoles.value) {
        if (props.allowedRoles.includes(r.name as Role)) {
            return true;
        }
    }

    return false;
})
</script>