<template>
    <aside :class="isOpen ? 'translate-x-0 ease-out' : '-translate-x-full ease-in'"
        class="fixed top-0 left-0 z-40 h-full pt-14 bg-white mt-3">
        <ul class="space-y-2 font-medium px-3 pb-4">
            <ProtectedComponent v-for="(item, index) in items" :key="index" :allowedRoles="item.allowedRoles">
                <li @click="toggleSubMenu(index)">
                    <div class="rounded-lg flex flex-row justify-between items-center hover:bg-gray-100 cursor-pointer"
                        :class="{ 'bg-gray-100': checkPath == item.to || checkIsOpen(item) }">
                        <SidebarItem :item="item" />
                        <Icon name="fa6-solid:angle-down" class="w-5 h-5 text-black m-2"
                            v-show="item.submenus && !item.isOpen" />
                        <Icon name="fa6-solid:angle-up" class="w-5 h-5 text-black m-2"
                            v-show="item.submenus && item.isOpen" />
                    </div>
                    <div v-for=" submenu in item.submenus " class="my-2 px-7 rounded-lg hover:bg-gray-100 cursor-pointer"
                        :class="{ 'bg-gray-100': checkPath == submenu.to }" v-show="submenu.isOpen">
                        <SidebarItem :item="submenu" />
                    </div>
                </li>
            </ProtectedComponent>
        </ul>
    </aside>
</template>

<script setup lang="ts">
import { Role } from '~/types/roles';

const route = useRoute();
defineProps<{
    isOpen: boolean
}>()

interface SubItem {
    title: string;
    to: string;
    isOpen: boolean;
}

interface Item {
    title: string;
    isOpen: boolean
    allowedRoles: Role[];
    icon: Array<string> | string;
    to?: string;
    submenus?: SubItem[];
}

const toggleSubMenu = (itemIndex: number) => {
    items[itemIndex].isOpen = !items[itemIndex].isOpen;
    const submenus = items[itemIndex].submenus;
    if (submenus) {
        submenus.forEach((submenu) => {
            submenu.isOpen = !submenu.isOpen;
        });
    }
};

const checkPath = computed(() => {
    return route.path
})

function checkIsOpen(item: Item) {
    if (item.submenus == null) {
        return false
    }
    return item.submenus.some(element => checkPath.value == element.to);
}


const items: Item[] = reactive([
    // {
    //     title: 'Dashboard',
    //     isOpen: false,
    //     allowedRoles: [Role.ADMIN, Role.LISTING_REVIEWER, Role.VALUATION_REVIEWER, Role.CAMPAIGNS_REVIEWER],
    //     icon: 'fa6-solid:chart-pie',
    //     to: '/',
    // },
    {
        title: 'Listings',
        isOpen: false,
        allowedRoles: [Role.ADMIN, Role.LISTING_REVIEWER],
        icon: ['far', 'list'],
        to: '/listings',
    },
    {
        title: 'Valuation',
        isOpen: false,
        allowedRoles: [Role.ADMIN, Role.VALUATION_REVIEWER],
        icon: ['far', 'chart-mixed-up-circle-dollar'],
        submenus: [
            {
                title: 'Analysis',
                to: '/valuation/analysis',
                isOpen: false,
            },
            // {
            //     title: 'Requests',
            //     to: '/valuation/requests',
            //     isOpen: false,
            // },
        ],
    },
    {
        title: 'Campaigns',
        isOpen: false,
        allowedRoles: [Role.ADMIN, Role.CAMPAIGNS_REVIEWER],
        icon: ['far', 'billboard'],
        to: '/campaigns',
    },
])
</script>